/**
 * --------------------------------------------------
 * Custom Smooth Scrolling 
 * @module Scroller
 * --------------------------------------------------
 */

import Lenis from '@studio-freight/lenis'
import ScrollerAnimations from './scroller-animations'

export default class Scroller {

    constructor() {
        // Lenis setup
        this.lenis = window.ontouchstart !== undefined ?
            new Lenis({ duration: 0 }) :
            new Lenis()
        this.ScrollerAnimations = new ScrollerAnimations(this.lenis)

        // down allow sroll on start of app
        //this._disable()

        // Animation frame for Lenis=
        this._raf = this.raf.bind(this)
        requestAnimationFrame(this._raf)
    }

    raf( time ) {
        this.lenis.raf(time)
        requestAnimationFrame(this._raf)
    }

    start() {
        this._enable()
    }

    _disable() {
        this.lenis.stop()
    }

    _enable() {
        this.lenis.start()
    }
}