/**
 * --------------------------------------------------
 * How to Step thru the scenes 
 * @module SceneManager
 * --------------------------------------------------
 */

export default class SceneManager { 

    constructor () {
        this.INTROOUTROTIME = 2000
        this.TRANSITIONTIME = 0
    }

    run() {
        const self = this
        self.runSite()
        CONDUCT.SCENEMANAGER.Scene('main')


        // Play background audio
        // If not allowed, display message
        // CONDUCT.$html.classList.add('scene-intro')
        // CONDUCT.BACKGROUNDAUDIO.attemptToPlay()
        //     .then(()=>{
        //         // Audio playing on load
        //         self.runIntroOutro().then(()=> { 
        //             self.runSite()
        //         })
        //     }).catch(function(error) {
        //         // don't skip
        //         self.runIntro()
        //     })
    }
    
    runIntro(){
        const self = this
        document.getElementById('TurnSoundOn').addEventListener('click', (e)=>{
            CONDUCT.BACKGROUNDAUDIO.play()
            self.runIntroOutro().then(()=> { 
                self.runSite()
            })
            e.preventDefault()
        })

        document.getElementById('BypassSound').addEventListener('click', (e)=>{
            self.runIntroOutro().then(()=> { 
                self.runSite()
            })
            e.preventDefault()
        })

    }

    async runIntroOutro() {
        const self = this
        return new Promise( (resolve) => {
            CONDUCT.$html.classList.add('scene-intro-transition')
            CONDUCT.SCENEMANAGER.Scene('intro-outro') // needed for react fiber to create events on component change
            setTimeout(()=>{
                CONDUCT.$html.classList.remove('scene-intro')
                CONDUCT.$html.classList.remove('scene-intro-transition')
                self._sceneTransition('main')
                resolve()
            }, CONDUCT.SCENEMANAGER.INTROOUTROTIME)
        })

    }

    async _sceneTransition(sceneName) {
        return new Promise( (resolve) => {
            CONDUCT.$html.classList.add('scene-transition')
            CONDUCT.$html.classList.add('scene-'+sceneName)
            setTimeout(()=>{
                CONDUCT.$html.classList.remove('scene-transition')
                resolve()
            }, CONDUCT.SCENEMANAGER.TRANSITIONTIME)
        })
    }

    runSite(){
        CONDUCT.SCROLLER.ScrollerAnimations.runAll()
        CONDUCT.SCROLLER.start()
    }
}